import React, { useState } from 'react';
import cx from 'classnames';
import { Input, Button, Form, notification } from 'antd';
import { doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { firebaseDB } from '../../firebase';
import {
  subscribe,
  contentCenter,
  inputBox,
  formContainer,
  subscribeButton,
  formItem,
} from './Subscribe.module.scss';

function Subscribe() {
  const [isFormLoading, setIsFormLoading] = useState(false);
  const handleOnFinish = async (values) => {
    setIsFormLoading(true);
    const { name, emailId } = values;
    try {
      const subscribeData = {
        name,
        emailId,
        subscribedAt: serverTimestamp(),
      };

      const docRef = doc(firebaseDB, 'subscribers', emailId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        notification.info({
          message: `You are already a subscriber ${name} ❤️`,
        });
      } else {
        await setDoc(doc(firebaseDB, 'subscribers', emailId), subscribeData);

        notification.info({
          message: `${name} welcome to the team`,
          description: `Thanks for subscribing my weekly newsletters.`,
        });
      }

      setIsFormLoading(false);
    } catch (e) {
      notification.error({
        message: 'Something Went wrong',
        description: e.message,
      });
      setIsFormLoading(false);
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Invalid information given',
      description:
        'Please provide valid name and email to subscribe my weekly newsletter for free!',
    });
  };

  return (
    <div className={subscribe}>
      <div className={cx('container', contentCenter)}>
        <h2>Enjoying my posts?</h2>
        <p>Subscribe to my sunday Snippets for free</p>

        <Form
          name="control-ref"
          onFinishFailed={onFinishFailed}
          onFinish={handleOnFinish}
          className={formContainer}
        >
          <Input.Group>
            <Form.Item
              name="name"
              className={formItem}
              rules={[
                {
                  required: true,
                  message: 'Please add your name',
                },
              ]}
            >
              <Input className={inputBox} placeholder="Your Name" />
            </Form.Item>
            <Form.Item
              name="emailId"
              className={formItem}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Is the above E-mail correct?',
                },
              ]}
            >
              <Input
                className={inputBox}
                style={{
                  borderLeft: '1px solid #cdcdcd',
                }}
                placeholder="Your Email"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className={subscribeButton}
                type="primary"
                loading={isFormLoading}
                style={{
                  background: '#fff',
                  color: '#111',
                  width: '20rem',
                }}
              >
                SUBSCRIBE
              </Button>
            </Form.Item>
          </Input.Group>
        </Form>
      </div>
    </div>
  );
}

export default Subscribe;
