import React from 'react';
import PropTypes from 'prop-types';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Header from './Navigation';
import Footer from './Footer';
import Subscribe from './Subscribe';

function Layout({ children, seoData }) {
  return (
    <>
      <GatsbySeo {...seoData} />
      <div>
        <Header />
        <div>{children}</div>
        <Subscribe />
        <Footer />
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  seoData: PropTypes.object,
};

Layout.defaultProps = {
  seoData: {},
};

export default Layout;
